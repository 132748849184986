<template>
  <div id="her-component" class="">
    <v-carousel
        class="carousel-container"
        v-model="carouselMe"
        hide-delimiter-background
        show-arrows
        cycle
        :continuous="true"
        width="400"
        interval="3800"
    >
      <v-carousel-item
          v-for="(image, index) in carouselImages"
          :key="index"
          :src="image"
          class="carousel-item"
      ></v-carousel-item>
    </v-carousel>
  </div>
</template>

<style>
#her-component {
  background-color: rgba(255,0,0,0.35);
  border-radius: 8px;
  padding: 32px 8px;
  margin: 0 8px;

  /* ARROWS */
  .v-window .v-window__container {
    padding: 8px;
    .v-window__controls {
      padding: 0;
      .v-btn {
        color: rgb(255,0,0);
        background: transparent;
      }
    }
  }

  /* DOTS */
  .v-window .v-carousel__controls {
    align-items: end;
    color: rgb(255,0,0);
  }

}
</style>


<script>
import { storeImage, getImage, imageExists } from '@/services/IndexedDBHelper';

export default {
  data() {
    return {
      carouselMe: 0,
      hardCarouselImages: [
        require('@/assets/her-component/braces-2.jpg'),
        require('@/assets/her-component/midriff.jpg'),
        require('@/assets/her-component/fit-chick.jpg'),
        require('@/assets/her-component/skirt.jpg'),
        require('@/assets/her-component/tomboy-cutie.jpg'),
        require('@/assets/her-component/gfe.jpg'),
        require('@/assets/her-component/jlynn.jpg'),
        require('@/assets/her-component/callie-caprakhan.jpg'),
        require('@/assets/her-component/mechanic-girl.jpg'),
        require('@/assets/her-component/louisegemmakentner.jpg'),
        require('@/assets/her-component/azn-cutie.jpg'),
        require('@/assets/her-component/braces.jpg'),
        require('@/assets/her-component/heilyn.jpg'),
        require('@/assets/her-component/heilyn-2.jpg'),
        require('@/assets/her-component/melonie-mac.jpg'),
        require('@/assets/her-component/melone-mac-2.jpg'),
        require('@/assets/her-component/eyeliner-lingerie.jpg'),
        require('@/assets/her-component/hannah-barron.jpg'),
        require('@/assets/her-component/soulmate-girl.jpg'),
        require('@/assets/her-component/doggy-pov.jpg'),
        require('@/assets/her-component/eager-handy.jpg'),
        require('@/assets/her-component/dakota-tyler.jpg'),
        require('@/assets/her-component/nose-ring-girl.jpg'),
        require('@/assets/her-component/nose-ring-girl-2.jpg'),
        require('@/assets/her-component/not-a-dick-pic.jpg'),
        require('@/assets/her-component/swimmer-chick.jpg'),
      ],
      carouselImages: [],
      imageUrls: [
        'https://stackinwins.com/img/braces-2.f438d77f.jpg',
        'https://stackinwins.com/img/midriff.4fd8e7e4.jpg',
        'https://stackinwins.com/img/fit-chick.026f1df5.jpg',
        'https://stackinwins.com/img/skirt.2242e257.jpg',
        'https://stackinwins.com/img/tomboy-cutie.d584b70e.jpg',
        'https://stackinwins.com/img/gfe.8af95353.jpg',
        'https://stackinwins.com/img/jlynn.2261961d.jpg',
        'https://stackinwins.com/img/callie-caprakhan.149eac95.jpg',
        'https://stackinwins.com/img/mechanic-girl.c907a42b.jpg',
        'https://stackinwins.com/img/louisegemmakentner.f0677a4c.jpg',
        'https://stackinwins.com/img/azn-cutie.3d40d903.jpg',
        'https://stackinwins.com/img/braces.ee7ea904.jpg',
        'https://stackinwins.com/img/heilyn.02c1c4b8.jpg',
        'https://stackinwins.com/img/heilyn-2.dbf555e1.jpg',
        'https://stackinwins.com/img/melonie-mac.5dfa7f67.jpg',
        'https://stackinwins.com/img/melone-mac-2.cf846e5a.jpg',
        'https://stackinwins.com/img/eyeliner-lingerie.b1305d66.jpg',
        'https://stackinwins.com/img/hannah-barron.48eb83b4.jpg',
        'https://stackinwins.com/img/soulmate-girl.fa2ac432.jpg',
        'https://stackinwins.com/img/doggy-pov.226bacf2.jpg',
        'https://stackinwins.com/img/eager-handy.3d230fae.jpg',
        'https://stackinwins.com/img/dakota-tyler.2914dd47.jpg',
        'https://stackinwins.com/img/nose-ring-girl.f8e91067.jpg',
        'https://stackinwins.com/img/nose-ring-girl-2.a1e9a7e1.jpg',
        'https://stackinwins.com/img/not-a-dick-pic.49c9faab.jpg',
        'https://stackinwins.com/img/swimmer-chick.5d516204.jpg',
      ],
    };
  },
  async created() {
    // When component is created, load the images
    this.carouselImages = await Promise.all(
        this.imageUrls.map((url) => this.loadImage(url))
    );
  },
  methods: {

    async loadImage(url) {
      const imageName = url.split('/').pop(); // Use image name as the key for IndexedDB

      // Check if the image exists in IndexedDB
      if (await imageExists(imageName)) {
        // If it exists, load it from IndexedDB
        return await getImage(imageName);
      } else {
        // Otherwise, fetch it from the webhost and store it in IndexedDB
        const response = await fetch(url);
        const blob = await response.blob();
        const base64Image = await this.convertBlobToBase64(blob);

        // Store the image in IndexedDB
        await storeImage(imageName, base64Image);

        return base64Image;
      }
    },

    convertBlobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },

  },
}
</script>

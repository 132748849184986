<template>
  <div id="me-component" class="">
<!--    <h2>Me Component</h2>-->
    <v-carousel
        class="carousel-container"
        v-model="carouselMe"
        hide-delimiter-background
        show-arrows
        cycle
        :continuous="true"
        width="400"
        interval="3800"
    >
      <v-carousel-item
          v-for="(image, index) in carouselImages"
          :key="index"
          :src="image"
          class="carousel-item"
      ></v-carousel-item>
    </v-carousel>
  </div>
</template>

<style>
#me-component {
  background-color: rgba(0,0,255,0.35);
  border-radius: 8px;
  padding: 32px 8px;
  margin: 0 8px;

  /* ARROWS */
  .v-window .v-window__container {
    padding: 8px;
    .v-window__controls {
      padding: 0;
      .v-btn {
        color: rgb(0, 0, 255);
        background: transparent;
      }
    }
  }

  /* DOTS */
  .v-window .v-carousel__controls {
    align-items: end;
    color: rgb(0,0,255);
  }

}
</style>


<script>
import { storeImage, getImage, imageExists } from '@/services/IndexedDBHelper';

export default {
  data() {
    return {
      carouselMe: 0,
      hardCarouselImages: [
        require('@/assets/me-component/arty.jpg'),
        require('@/assets/me-component/jahn-airsoft-2.jpg'),
        require('@/assets/me-component/gsus-swole-bread-water.jpg'),
        require('@/assets/me-component/gsus-swole-cross.jpg'),
        require('@/assets/me-component/soldier.jpg'),
        require('@/assets/me-component/quote-2.jpg'),
        require('@/assets/me-component/quote-6.jpg'),
        require('@/assets/me-component/tyson.jpg'),
        require('@/assets/me-component/tyson-2.jpg'),
        require('@/assets/me-component/tyson-3.jpg'),
        require('@/assets/me-component/boxing-slip.jpg'),
        require('@/assets/me-component/goggins-seal.jpg'),
        require('@/assets/me-component/nam-2.jpg'),
        require('@/assets/me-component/nam.jpg'),
        require('@/assets/me-component/quote-4.jpg'),
        require('@/assets/me-component/quote-5.jpg'),
        require('@/assets/me-component/omar.jpg'),
        require('@/assets/me-component/julian.jpg'),
        require('@/assets/me-component/quote-1.jpg'),
        require('@/assets/me-component/tony-ducks.jpg'),
      ],
      carouselImages: [],
      imageUrls: [
        'https://stackinwins.com/img/arty.1c3962d3.jpg',
        'https://stackinwins.com/img/jahn-airsoft-2.e6f67553.jpg',
        'https://stackinwins.com/img/gsus-swole-bread-water.174f71c6.jpg',
        'https://stackinwins.com/img/gsus-swole-cross.983357fd.jpg',
        'https://stackinwins.com/img/soldier.50095f49.jpg',
        'https://stackinwins.com/img/quote-2.70677756.jpg',
        'https://stackinwins.com/img/quote-6.6e4ca3e1.jpg',
        'https://stackinwins.com/img/tyson.63935e69.jpg',
        'https://stackinwins.com/img/tyson-2.660a186d.jpg',
        'https://stackinwins.com/img/tyson-3.5128e0f7.jpg',
        'https://stackinwins.com/img/boxing-slip.9cdf94b9.jpg',
        'https://stackinwins.com/img/goggins-seal.e475e9cc.jpg',
        'https://stackinwins.com/img/nam-2.b821ff16.jpg',
        'https://stackinwins.com/img/nam.dfd13cb9.jpg',
        'https://stackinwins.com/img/quote-4.48fbef76.jpg',
        'https://stackinwins.com/img/quote-5.af341230.jpg',
        'https://stackinwins.com/img/omar.dcb3d266.jpg',
        'https://stackinwins.com/img/julian.e84ff73e.jpg',
        'https://stackinwins.com/img/quote-1.f2c880e2.jpg',
        'https://stackinwins.com/img/tony-ducks.a3dc89ee.jpg',
      ],
    };
  },
  async created() {
    // When component is created, load the images
    this.carouselImages = await Promise.all(
        this.imageUrls.map((url) => this.loadImage(url))
    );
  },
  methods: {

    async loadImage(url) {
      const imageName = url.split('/').pop(); // Use image name as the key for IndexedDB

      // Check if the image exists in IndexedDB
      if (await imageExists(imageName)) {
        // If it exists, load it from IndexedDB
        return await getImage(imageName);
      } else {
        // Otherwise, fetch it from the webhost and store it in IndexedDB
        const response = await fetch(url);
        const blob = await response.blob();
        const base64Image = await this.convertBlobToBase64(blob);

        // Store the image in IndexedDB
        await storeImage(imageName, base64Image);

        return base64Image;
      }
    },

    convertBlobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },

  },
}
</script>

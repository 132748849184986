<template>
  <div id="family-friends" class="">
    <v-carousel
        class="carousel-container"
        v-model="carouselMe"
        hide-delimiter-background
        show-arrows
        cycle
        :continuous="true"
        width="400"
        interval="3800"
    >
      <v-carousel-item
          v-for="(image, index) in carouselImages"
          :key="index"
          :src="image"
          class="carousel-item"
      ></v-carousel-item>
    </v-carousel>
  </div>
</template>

<style>
#family-friends {
  background-color: rgba(0,255,0,0.35);
  border-radius: 8px;
  padding: 32px 8px;
  margin: 0 8px;

  /* ARROWS */
  .v-window .v-window__container {
    padding: 8px;
    .v-window__controls {
      padding: 0;
      .v-btn {
        color: rgb(0,255,0);
        background: transparent;
      }
    }
  }

  /* DOTS */
  .v-window .v-carousel__controls {
    align-items: end;
    color: rgb(0,255,0);
  }

}
</style>


<script>
import { storeImage, getImage, imageExists } from '@/services/IndexedDBHelper';

export default {
  data() {
    return {
      carouselMe: 0,
      hardCarouselImages: [
        require('@/assets/family-friends/bleu.jpg'),
        require('@/assets/family-friends/bob.jpg'),
        require('@/assets/family-friends/caesar.jpg'),
        require('@/assets/family-friends/jahn-airsoft.jpg'),
        require('@/assets/family-friends/gsus-crossover.jpg'),
        require('@/assets/family-friends/american-breakfast.jpg'),
        require('@/assets/family-friends/dazed-confused.jpg'),
        require('@/assets/family-friends/kbw.jpg'),
      ],
      carouselImages: [],
      imageUrls: [
        'https://stackinwins.com/img/bleu.70777f2b.jpg',
        'https://stackinwins.com/img/bob.e19a20e1.jpg',
        'https://stackinwins.com/img/caesar.813cc823.jpg',
        'https://stackinwins.com/img/jahn-airsoft.8d325505.jpg',
        'https://stackinwins.com/img/gsus-crossover.80e5dccc.jpg',
        'https://stackinwins.com/img/american-breakfast.9d990c1b.jpg',
        'https://stackinwins.com/img/dazed-confused.c5902f62.jpg',
        'https://stackinwins.com/img/kbw.a73bd121.jpg',
      ],
    };
  },
  async created() {
    // When component is created, load the images
    this.carouselImages = await Promise.all(
        this.imageUrls.map((url) => this.loadImage(url))
    );
  },
  methods: {

    async loadImage(url) {
      const imageName = url.split('/').pop(); // Use image name as the key for IndexedDB

      // Check if the image exists in IndexedDB
      if (await imageExists(imageName)) {
        // If it exists, load it from IndexedDB
        return await getImage(imageName);
      } else {
        // Otherwise, fetch it from the webhost and store it in IndexedDB
        const response = await fetch(url);
        const blob = await response.blob();
        const base64Image = await this.convertBlobToBase64(blob);

        // Store the image in IndexedDB
        await storeImage(imageName, base64Image);

        return base64Image;
      }
    },

    convertBlobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },

  },
}
</script>

<template>
<div id="reminders-page-wrapper">

  <h1 class="text-center mt-5 mb-3">DAILY OBSESSIONS</h1>

  <div class="px-8">
    <text-reminders></text-reminders>
  </div>

  <div class="px-0 py-16">
    <me-component></me-component>
  </div>

  <div class="px-0 py-16">
    <her-component></her-component>
  </div>

  <div class="px-0 py-16">
    <family-friends></family-friends>
  </div>

</div>
</template>

<script>
import TextReminders from './TextReminders.vue';
import MeComponent from './MeComponent.vue';
import HerComponent from './HerComponent.vue';
import FamilyFriends from './FamilyFriends.vue';
export default {
  components: {
    TextReminders,
    MeComponent,
    HerComponent,
    FamilyFriends
  },
  props: [],
  data() {
    return {

    };
  },
  watch: [],
  methods: {},
}
</script>



<style scoped>
#reminders-page-wrapper {}
</style>

<script>
export default {
  name: "TextReminders"
}
</script>

<template>
  <div id="text-reminders mt-3 mb-5">
    <ul class="pl-4">
      <li>
        Visualize the MAN/CHARACTER you want to be/have. Then act like him, and you will <em>be</em> Him.
        <ul id="nested-list" class="pl-5">
          <li>calm, carefree in the face of all trespasses against me — God will defend me and avenge me.</li>
          <li>no problem or obstacle will defeat me. i survive and overcome all, until God calls me home.</li>
          <li>i live and leave a legacy of kindness, love/acceptance, and guiding/coaching people to better lives. i am a candle carrying a flame in the darkness; i light other candles.</li>
          <li>live with no regrets. use my time wisely so i don't cry and beg for more when my time comes.</li>
        </ul>
      </li>
      <li>Fit, muscular, <em>dangerous</em></li>
      <li>Multimillionaire. <em>So much money</em> that normal life does not worry me anymore.</li>
      <li>Gorgeous, beautiful wife.</li>
      <li>My mom and dad, we have good relationships.</li>
      <li>My land is beautiful, peaceful, and prosperous.</li>
      <li>Multilingual, mastery of 3 languages.</li>
      <li>Dress sharp. New friends comprising other Real, Good Men.</li>
    </ul>
  </div>
</template>

<style scoped>
  #text-reminders {
    font-size: 18px;
  }
  ul:not(#nested-list) > li {
    margin-bottom: 12px;
    font-weight: bold;
  }
  ul#nested-list {
    margin-top: 8px;
    li {
      margin-bottom: 4px;
      font-weight: normal;
    }
  }
</style>

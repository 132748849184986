import { openDB } from 'idb';

// Open or create the database
const dbPromise = openDB('image-store', 1, {
    upgrade(db) {
        db.createObjectStore('images');
    },
});

// Store image in IndexedDB
async function storeImage(key, imageData) {
    const db = await dbPromise;
    await db.put('images', imageData, key);
}

// Get image from IndexedDB
async function getImage(key) {
    const db = await dbPromise;
    return await db.get('images', key);
}

// Check if image exists in IndexedDB
async function imageExists(key) {
    const db = await dbPromise;
    const result = await db.get('images', key);
    return result !== undefined;
}

export {storeImage, getImage, imageExists};
